import '@amedia/brick-icon';
import { css, sroStyle } from '@amedia/brick-tokens';

import { type Payload } from '../../types/attributes';

const topWrapper = css({
  display: 'flex',
  alignItems: 'initial',
  justifyContent: 'space-between',
  padding: '$x4 $x4 $x3',
  gridArea: 'top',

  variants: {
    small: {
      true: {
        padding: '$x3 $x3 $x4',
      },
    },
    feature: {
      true: {
        background: `linear-gradient(
            rgba(18,18,18,0.88) 0%,
            rgba(18,18,18,0.56) 42.53%,
            rgba(18,18,18,0.4) 76.64%,
            rgba(0,0,0,0) 100%
        )`,
        position: 'relative',
        height: 'min-content',
        zIndex: 1,
      },
    },
  },
});

const publicationWrapper = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$x2',
  textDecoration: 'none',
});

const logoStyle = (logoUrl: string) =>
  css({
    background: `url(${logoUrl}) center center / cover no-repeat #fff;`,
    height: '32px',
    width: '32px',
    borderRadius: '99999px',

    variants: {
      small: {
        true: {
          height: '24px',
          width: '24px',
        },
      },
    },
  })();

const nameTagsWrapper = css({
  display: 'flex',
  flexDirection: 'column',
});

const publicationName = css({
  color: '$baseFg',
  fontstyle: 'baseHeadlineXs',
  margin: '$none',

  variants: {
    small: {
      true: {
        fontWeight: '400',
      },
    },
    feature: {
      true: {
        color: '$baseInvertedFg',
      },
    },
  },
});

const taglistStyle = css({
  listStyleType: 'none',
  margin: '$none',
  padding: '$none',
  ':not(:first-child)::before': {
    content: '•',
    padding: '$none $x1',
  },
});

const tagElementStyle = css({
  color: '$baseFg',
  fontstyle: 'baseMetaM',
  display: 'inline-block',
  ':not(:first-of-type)::before': {
    content: '•',
    padding: '$none $x1',
  },
  variants: {
    feature: {
      true: {
        color: '$baseInvertedFg',
      },
    },
  },
});

const teaserMenuStyle = css({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '$none',
  margin: '$none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32px',
  width: '32px',

  '& span': {
    display: 'block',
    transform: 'scale(1)',
    transition: 'transform 30ms ease-out',
  },

  '&:hover span,&:focus-visible span': {
    transform: 'scale(0.95)',
  },
  '&:active span': {
    transform: 'scale(0.92)',
  },

  variants: {
    featureImageType: {
      true: {
        '& brick-icon-v2 svg': {
          filter: 'invert(1)',
        },
      },
    },
  },
});

const top = (data: typeof Payload) => {
  const { publication, name, tags, small, feature, image } = data;

  const featureImageType = !!image && feature;

  const renderLogo = () => {
    const logo = `https://r.acdn.no/local/v3/publications/${publication}/gfx/square.svg`;
    return publication
      ? `<span class="${logoStyle(
          logo
        )}"><span class="${sroStyle()}">Gå til avisprofil for ${name}</span></span>`
      : '';
  };

  const tagsArr = tags.split(',').filter((tag) => tag !== '');
  const renderTags =
    tagsArr.length && !small
      ? `<ul class="${taglistStyle()}">
      ${tagsArr
        .map(
          (tag) =>
            `<li class="${tagElementStyle({
              feature: featureImageType,
            })}">${tag}</li>`
        )
        .join('')}
      </ul>`
      : '';

  return /* html */ `
    <div class="${topWrapper({ small, feature: featureImageType })}">
      <a data-adp-clickvalue="publication" href="?sitekey=${
        data.siteId
      }#site-profile" class="${publicationWrapper({ small })}">
        ${renderLogo()}
        <div class="${nameTagsWrapper()}">
          <span class="${publicationName({
            feature: featureImageType,
          })}">${name}</span>
          ${renderTags}
        </div>
      </a>
      <button type="button" data-adp-label="menu-teaser" data-adp-clickvalue="open-menu" class="${teaserMenuStyle(
        { featureImageType }
      )}" aria-expanded="false" >
        <span><brick-icon-v2 data-icon-id="more-vertical"></brick-icon-v2></span>
        <span class=${sroStyle()}>Åpne valgmeny for artikkelen</span>
     </button>
    </div>
  `;
};
export default top;
