import { css, sroStyle } from '@amedia/brick-tokens';

import adpTest from './adp';
import { teaserImage } from './components/image/image';
import metadata from './components/metadata/metadata';
import { opinion } from './components/opinion/opinion';
import title from './components/title/title';
import top from './components/top/top';
import { video } from './components/video/video';
import type { Payload } from './types/attributes';
import { getHumanReadableDate } from './utils';

function articleStyle(opts = { backgroundColor: '' }) {
  const { backgroundColor } = opts;
  return css({
    backgroundColor: backgroundColor || '#d3d9de',
    borderRadius: '$teaser',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    gridTemplateAreas: `
        "top"
        "title"
        "image"
    `,

    '& :where(a:focus-visible), :where(button:focus-visible)': {
      outline: '2px ridge black',
      borderRadius: '4px',
    },

    variants: {
      layout: {
        'image-bottom': {},
        small: {
          gridTemplateColumns: '2fr 3fr',
          gridTemplateRows: '0fr auto',
          gridTemplateAreas: `
                "image title"
                "image top"
            `,
        },
        opinion: {
          gridTemplateAreas: `
                "top"
                "image"
                "title"
            `,
        },
        'image-top': {
          gridTemplateRows: 'auto',
          gridTemplateAreas: `
              'top'
              'title'
            `,
        },
        'video-top': {
          gridTemplateAreas: `
              'image'
              'top'
              'title'
            `,
        },
      },
    },
  });
}

const mainLinkStyle = css({
  textDecoration: 'none',
  display: 'block',

  '&:visited': {
    color: '$baseFg',
  },

  '&:focus-visible': {
    borderRadius: '12px',
    outlineOffset: '-2px',
  },

  variants: {
    feature: {
      true: {},
    },
    area: {
      image: {
        gridArea: 'image',
      },
      title: {
        gridArea: 'title',
        padding: '$x3 $x4 $x4',
      },
      top: {
        gridArea: 'top',
        zIndex: 0,
      },
    },
    small: {
      true: {},
    },
    opinion: {
      true: {
        padding: '$x1 $x4 0',
      },
    },
  },
  compoundVariants: [
    {
      area: 'title',
      small: true,
      css: {
        padding: '$x4 $x3 0',
      },
    },
    {
      area: 'title',
      feature: true,
      css: {
        padding: '$x6',
      },
    },
  ],
});

export const template = (
  data: typeof Payload,
  layout: 'image-top' | 'image-bottom' | 'video-top' | 'opinion'
) => {
  const { attributes: adpAttributes = '', metatags: adpMetaTags = '' } =
    adpTest(data);

  const published = getHumanReadableDate(data.published);

  return /* html */ `
<article class="${articleStyle({
    backgroundColor: data.backgroundColor,
  })({
    layout,
  })}" itemscope itemtype="http://schema.org/NewsArticle" ${adpAttributes}>
  <meta itemprop="https://www.adplogger.no/json-schema/meta-element#adpType" content="teaser" />
  <meta itemprop="identifier" content="${data.articleId}" />
  ${adpMetaTags}
  <!-- Aria label is used to make more sense of the title for screen readers -->
  <span class="${sroStyle}" id="label-${data.articleId}">${
    data.title
  }. Publisert av ${data.name}, ${published}.</span>
  <a class="${mainLinkStyle({
    area: 'title',
    feature: data.feature,
  })}" href="${
    data.link
  }" itemprop="url" data-adp-clickLabel="teaser" data-adp-clickValue="read-article" aria-labelledby="label-${
    data.articleId
  }"
  >
    ${title(data)}
    ${opinion(data)}
    ${metadata(data)}
</a>
${top(data)}
  ${
    data.isVideo
      ? `
    ${video(data)}
  `
      : /* html */ `
    <a class="${mainLinkStyle({
      area: data.feature ? 'top' : 'image',
      opinion: layout === 'opinion',
      feature: data.feature,
    })}" href="${
      data.link
    }" itemprop="url" data-adp-clickLabel="teaser" data-adp-clickValue="read-article" aria-hidden="true" tabindex="-1">
      ${teaserImage(data)}
    </a>
  `
  }
</article>
`;
};
