import { BrickPlayer } from '@amedia/brick-player';
import { css } from '@amedia/brick-tokens';

import { Payload } from '../../types/attributes';

export const video = (data: typeof Payload) => {
  const { playerId, videoId } = data;

  const wrapper = css({
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',

    '.fp-color': {
      backgroundColor: '#fff !important',
    },

    video: {
      borderRadius: '$teaser $teaser 0 0',
    },
  });

  return /*html*/ `
    <div class=${wrapper()}>
      <brick-player mediaid="${videoId}" playerid="${playerId}" autoplay="true" muted="true" teasertitle="true" adsdisabled="true" ui="NO_HEADER" />
    </div>
  `;
};

if (!customElements.get('brick-player')) {
  customElements.define('brick-player', BrickPlayer);
}
