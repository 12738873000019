import type { Payload } from './types/attributes';

const escapeHTML = (s) => {
  const lookup = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;',
  };
  return s && s.replace(/[&"<>]/g, (c) => lookup[c]);
};

const adpTest = (data: typeof Payload) => {
  const { articleId, testId, variantId, siteId } = data;

  if (!testId) return {};

  const teaserId = articleId.replace(/-[a-z]$/, ''); // data.id.replace(/-[a-z]$/, '');

  const attributes = `
    data-adp-siteid="${escapeHTML(siteId)}"
    data-adp-teaserid="${escapeHTML(teaserId)}"
    data-adp-variantid="${escapeHTML(variantId)}"
    data-adp-testid="${escapeHTML(testId)}"
  `;

  const metatags = `
    <meta
      itemprop="https://www.adplogger.no/json-schema/meta-teaser#testid"
      content="${escapeHTML(testId)}"
    />
    <meta
      itemprop="https://www.adplogger.no/json-schema/meta-teaser#variant"
      content="${escapeHTML(variantId)}"
    />
  `;

  return { attributes, metatags };
};

export default adpTest;
