import '@amedia/brick-image';

import type { Payload } from '../../types/attributes';

import { galleryWrapStyle, imageStyle } from './image-style';

export const teaserImage = (data: typeof Payload) => {
  const { image, imagesizes, altText, gallery, feature, opinion, srcset } =
    data;
  if (!image) return '';

  const galleryMap = gallery
    .replace(/\s{2}/, '')
    .split(',')
    .map(
      (g) =>
        `<brick-image-v3 data-src="${g}" border="rounded"></brick-image-v3>`
    );

  const galleryString = galleryMap.join('');

  return gallery
    ? `<div class="${galleryWrapStyle()}">${galleryString}</div>`
    : `
      <brick-image-v3
        class="${imageStyle({ feature, opinion })}"
        data-src="${image}"
        data-srcset="${srcset}"
        data-sizes"${imagesizes}"
        data-alt-text="${altText}"
      ></brick-image-v3>
  `;
};
